import { Container } from "@/components/Shared/Container/Container";
import { MarkdownRenderer } from "@/components/Shared/MarkdownRenderer/MarkdownRenderer";
import { SectionHeader } from "@/components/Shared/SectionHeader/SectionHeader";
import React from "react";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const GdorPage = (props: any) => {
  return (
    <Layout>
      <SEO description="This is an index page" />
      <Container py={20}>
        <SectionHeader title={"Ochrana osobných údajov"} />
        <MarkdownRenderer
          children={`

## Súhlas so spracovaním osobných údajov

Súhlasom so spracovaní osobných údajov povoľuje dotknutá osoba spoločnosti PrenatBaby, so sídlom Školská 35, Šamorín, 931 01 Slovenská republika,
(Ďalej len "Správca"), aby v zmysle zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej len "zákon o ochrane osobných údajov") spracovávala tieto osobné údaje dotknutej osoby:

*   meno a priezvisko
*   e-mail
*   telefónne číslo

Meno, priezvisko, názov spoločnosti, adresa, telefónne číslo a e-mail je nutné spracovať za účelom spracovania objednávky.
Tieto údaje budú Správcom spracované po dobu niekoľkých rokov.

### Spracovanie osobných údajov

Súhlasom so spracovaní osobných údajov udeľuje dotknutá osoba svoj výslovný súhlas s vyššie uvedeným spracovaním. 
Súhlas možno vziať kedykoľvek späť, a to napríklad zaslaním emailu alebo zaslaním listu na kontaktné údaje správcovskej spoločnosti.

Spracovanie osobných údajov je vykonávané Správcom. Osobné údaje môžu spracovávať aj títo spracovatelia:
    *   Poskytovateľ webhostingu Websupport
    *   Prípadne ďalší poskytovatelia alebo dodávatelia webových systémov, služieb a aplikácií, ktoré v súčasnej dobe spoločnosť využíva \[vymenovať\]/nevyužíva.
    *   Netlify, Heroku

### Práva na opravu, zabudnutie a prístup k osobným údajom dotknutej osoby

Prosím vezmite na vedomie, že podľa zákona o ochrane osobných údajov má dotknutá osoba právo:
*   vziať súhlas kedykoľvek späť,
*   vyžiadať od nás informáciu, aké vaše osobné údaje spracovávame,
*   vyžiadať od nás vysvetlenie ohľadne spracovania osobných údajov,
*   vyžiadať si u nás výpis týchto údajov a tieto nechať aktualizovať alebo opraviť,
*   požadovať od nás vymazanie týchto osobných údajov,
*   v prípade pochybností o dodržiavaní povinností súvisiacich so spracovaním osobných údajov obrátiť sa na nás alebo na Úrad na ochranu osobných údajov.

# Cookies 

Cookies sú malé textové súbory, ktoré môžu byť použité webovými stránkami, aby zefektívnili užívateľovu skúsenosť.

Zákon hovorí, že môžeme ukladať súbory cookies na vašom zariadení, ak sú nevyhnutné pre prevádzku týchto stránok. Pri všetkých ostatných typoch súborov cookies potrebujeme váš súhlas.

Táto stránka používa rôzne typy cookies. Niektoré cookies sú tu umiestnené z dôvodu využitia služieb tretích strán.

Potrebné súbory cookies pomáhajú vytvárať použiteľné webové stránky tak, aby umožnili základné funkcie, ako je navigácia stránky a prístup k chráneným oblastiam webových stránok. Webové stránky nemôžu riadne fungovať bez týchto súborov cookies.

Kategória: Potrebné (2):

1.Stav súhlasu používateľa cookies pre aktuálnu doménu
2.Uchováva sa stav relácie používateľa vo všetkých požiadavkách na stránku.
3.atď.
u
Kategória: Štatistika (3):

1.Registruje sa jedinečné ID, ktoré sa používa na generovanie štatistických údajov o tom, ako návštevník používa webové stránky.
2.Používa sa služba Google Analytics na hodnotenie rýchlosti požiadaviek.
3.Registruje sa jedinečné ID, ktoré sa používa na generovanie štatistických údajov o tom, ako návštevník používa webové stránky.
4.atď.`}
        />
      </Container>
    </Layout>
  );
};

export default GdorPage;
